import React from "react";
import "./Header.css";
import {Layout, Space} from "antd";

const {Header: BasicHeader} = Layout;

const Header = () => {
    return <BasicHeader id="header">
        <h1 style={{marginTop: "15px"}}>Husky Network</h1>
    </BasicHeader>
}

export default Header;