import {useEffect, useState} from "react";
// import "./PostContainer.css";
import {List, Pagination} from 'antd';
import PostArea from "../PostArea/PostArea";

const PostContainer = () => {
    const [posts, setPosts] = useState([]);

    const queryPosts = async () => {
        try {
            const response = await fetch('https://backend-service.boyang.workers.dev/posts', {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            const {data} = await response.json();
            const posts = []
            for (let post of data) {
                posts.push(JSON.parse(post));
            }
            return posts;
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    useEffect(() => {
        queryPosts().then((posts) => {
            setPosts(posts);
        }).catch((err) => {
            console.error(err.message);
        });
    }, []);

    return <>
        <List
            // itemLayout="vertical"
            dataSource={posts}
            size="large"
            pagination={{
                onChange: page => {
                    console.log(page);
                },
                pageSize: 10,
            }}
            renderItem={post => (
                <List.Item>
                    <List.Item.Meta
                        title={post.title}
                        description={"@" + post.username}
                    />
                    {post.content}
                </List.Item>
            )}
        />
        <PostArea/>
    </>
}

export default PostContainer;