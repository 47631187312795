import "./app.css";
import PostContainer from "./componets/PostContainer/PostContainer"
import Header from "./componets/Headline/Header";
import {Layout} from "antd";

const App = () => {
    const {Content} = Layout;
    return <Layout id="layout-container">
        <Header/>
        <Content className={"content-container"}>
            <PostContainer/>
        </Content>
    </Layout>;
}

export default App;
