import {Button, Input} from 'antd';
import {useState} from "react";
import "./PostArea.css";


const PostArea = ({toggleUpdate}) => {
    const {TextArea} = Input;
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [username, setUsername] = useState("");
    const submitPost = async post => {
        try {
            console.log(post);
            const response = await fetch('https://backend-service.boyang.workers.dev/posts', {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(post)
            });
        } catch (err) {
            console.error(err.message, err.stack);
        }
    }

    return <div className="post-area-container">
        <Input id="title-input" placeholder="Input title" allowClear onChange={event => setTitle(event.target.value)}/>
        <TextArea id="content-input" placeholder="Input content" allowClear
                  onChange={event => setContent(event.target.value)}/>
        <Input id="username-input" placeholder="Input username" allowClear
               onChange={event => setUsername(event.target.value)}/>
        <Button className="button" onClick={() => submitPost({
            title,
            content,
            username
        })}>Submit</Button>
    </div>
}

export default PostArea;